<template>
  <div>
    <a-modal
      v-model="visible"
      :title="$t('货品选择')"
      width="1200px"
      :destroyOnClose="true"
      @cancel="onCloseModel"
      @ok="onConfirm"
    >
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="width: 256px">
          <a-input-search v-model="searchForm.search" :placeholder="$t('货品编码, 名称, 条码')" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.status" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
            <a-select-option key="qualified" value="qualified">{{ $t("良品") }}</a-select-option>
            <a-select-option key="unqualified" value="unqualified">{{ $t("不良品") }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.batch_number" :placeholder="$t('批次编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.pallet_number" :placeholder="$t('容器编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.location_number" :placeholder="$t('库位编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>
      </a-row>

      <div style="margin-top: 12px">
        <a-table
          :data-source="items"
          size="small"
          rowKey="id"
          :columns="columns"
          :loading="loading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="onChangeTable"
        >
          <div slot="action" slot-scope="value, item, index">
            <a-button size="small" @click="select(item)">{{ $t("选择") }}</a-button>
          </div>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { subBatchOption } from "@/api/option";

import { columns } from "./columns";

export default {
  props: ["visible", "warehouse"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      columns,
      pagination: {},
      searchForm: {},
      loading: false,
      items: [],

      selectedRowKeys: [],
    };
  },
  methods: {
    onCloseModel() {
      this.$emit("cancel", false);
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      this.loading = true;
      let params = {
        ...this.searchForm,
        warehouse: this.warehouse,
      };

      subBatchOption(params)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select(item) {
      this.$emit("select", item);
      this.onCloseModel();
    },
    resetModel() {
      this.pagination = {
        current: 1,
        total: 0,
        pageSize: 16,
        showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}`,
      };
      this.searchForm = { page: 1 };
      this.items = [];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onConfirm() {
      const dataItems = [];
      for (let selectedRowKeys of this.selectedRowKeys) {
        for (const item of this.items) {
          if (selectedRowKeys == item.id) {
            dataItems.push(item);
            break;
          }
        }
      }

      this.$emit("confirm", dataItems);
      this.onCloseModel();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.resetModel();
        this.list();
      }
    },
  },
};
</script>
